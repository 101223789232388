.testing-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    margin: 0;
}

.carousel-wrapper {
    position: relative;
    width: fit-content;
}

.testing-carousel-container {
    position: relative;
    margin: 0;
    width: 180rem;
    height: 100%;
    overflow: hidden;
    user-select: none;
    -webkit-user-drag: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
}


.testing-carousel-card-container {
    display: flex;
    align-items: center;
    justify-content: start;
    flex-wrap: nowrap;
    gap: 3.125rem;
    width: fit-content;
    height: 100%;
    transition: all .3s;
}

.testing-carousel-right-arrow {
    width: 4rem;
    height: 4rem;
    position: absolute;
    cursor: pointer;
    transform: rotate(-90deg) translate(50%, -50%);
    right: -1%;
    top: 50%;
    fill: #99be14;
    z-index: 999;
}

.testing-carousel-left-arrow {
    width: 4rem;
    height: 4rem;
    position: absolute;
    cursor: pointer;
    transform: rotate(90deg) translate(-50%, -50%);
    left: -1%;
    top: 50%;
    stroke: white;
    fill: #99be14;
    z-index: 999;
}

.disabled-arrow {
    pointer-events: none;
    opacity: 0%;
}

.outstanding-carousel-card {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 33.5rem;
    height: 58.3rem;
    background-color: white;
    border: 1px solid black;
}

.card-icons {
    content: "";
    position: absolute;
    top: 3rem;
    right: 1rem;
    display: flex;
    flex-direction: column;
    z-index: 0;
    gap: 1rem;
    cursor: pointer;
}

.landing-carousel-icon {
    width: 3.6rem;
    height: 3.6rem;
    border-radius: 50%;
    background-color: white;
    z-index: 0;
}


@media (min-width: 350px) and (max-width: 749px) {

    .testing-carousel-container {
        width: 66.5rem;
        padding-left: 8.25rem;
        padding-right: 8.25rem;
    }

    .outstanding-carousel-card {
        width: 50rem;
    }

    .testing-carousel-card-container {
        gap: 16.51rem;
    }

}

@media (min-width: 750px) and (max-width: 1299px) {

    .testing-carousel-container {
        width: 142.5rem;
    }

    .outstanding-carousel-card {
        width: 46rem;
    }

    .testing-carousel-card-container {
        gap: 1.5rem;
    }
}


@media (min-width: 1300px) and (max-width: 1899px) {
    .outstanding-carousel-card {
        width: 43.6rem;
    }

    .testing-carousel-card-container {
        gap: 1.4rem;
    }
}




