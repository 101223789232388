aside.catalogue-filter {
    position: sticky;
    display: flex;
    flex-direction: column;
    align-self: start;
    gap: 2.5rem;
    padding: 2rem;
    width: 45rem;
    height: fit-content;
    background-color: white;
    border-radius: 2.5rem;
    top: calc(var(--navbar-height) + 13rem);
    left: 0;
    overflow-y: auto;
    max-height: 70vh;
    margin-bottom: 3rem;
    z-index: 999;
}

.catalogue-filter > h2 {
    color: black;
    margin-bottom: 0;
}

.catalogue-input-container {
    display: flex;
    flex-direction: row;
    width: 75%;
    align-items: center;

    border-radius: 2.5rem;
    border: 2px solid black;
    height: 4rem;


}

.catalogue-filter-input {
    width: 100%;
    height: 3rem;
    border: none;
    outline: none;
    border-radius: 2.5rem;
    padding-left: 1rem;
}

.catalogue-filter-search-icon {
    width: 4rem;
    height: 4rem;
    padding: 0.7rem;
    fill: red;
    color: black !important;
    stroke: white;
    background-color: black;
    border-top-right-radius: 2.5rem;
    border-bottom-right-radius: 2.5rem;
}

.catalogue-category-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    cursor: pointer;
}

.catalogue-category-header > h3 {
    color: black;
}

.catalogue-category-arrow {
    width: 2rem;
    height: 2rem;
    transition: 0.3s all;
}

.catalogue-category-arrow-active {
    fill: var(--original-light-green);
    transform: rotate(-90deg);

}

.catalogue-category-body {
    width: 100%;
    max-height: 0;
    gap: .7rem;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    list-style: none;
    transition: 0.3s max-height;
    overflow: hidden;
}

.catalogue-category-body-active {
    max-height: 150vh;

}

@media (min-width: 350px) and  ( max-width: 749px) {
    aside.catalogue-filter {
        top: var(--navbar-height);
        left: 0;
        width: 100vw;
        border-radius: 0;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: row;
        overflow: visible;
    }

    .catalogue-filter > h2 {
        display: none;
    }

    .catalogue-filter-category {
        width: fit-content;
        overflow: visible;
    }

    .catalogue-category-header {
        margin: 0;
    }

    .catalogue-category-body {
        background-color: white;
        width: 100vw;
        max-height: 20vh;
        position: absolute;
        bottom: -11rem;
        transform: translateY(100%);
        left: -100%;
        z-index: 999999999999999999999999999999999999;
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 1rem;
        overflow: scroll;
        transition: all .3s;
        border-bottom-right-radius: 2.5rem;
        border-bottom-left-radius: 2.5rem;
        box-shadow: var(--box-shadow-down);
        padding-bottom: 1rem;
    }

    .catalogue-category-body-active {
        max-height: 20vh;
        left: 0;
        bottom: -11rem;
        transform: translateY(100%);
    }

    .catalogue-category-body > li {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
        padding: 1rem 1rem;
    }

    .catalogue-category-body > li > input {
        height: 1.5rem;
    }


    .catalogue-category-arrow {
        display: none;
    }


    .catalogue-input-container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        height: 11rem;
        background-color: white;
        bottom: 0;
        left: 0;
        width: 100%;
        transform: translateY(100%);
        border-radius: 0;
        border: none;

    }

    .catalogue-filter-input {
        width: 80%;
        height: 5rem;
        padding: 1rem 2rem;
        border: 1px solid silver;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .05);
        border-radius: 2.5rem;
    }

    .catalogue-filter-search-icon {
        stroke-width: 2px;
        stroke: #fff;
        fill: #fff;
        background-color: var(--original-light-green);
        border-radius: .5rem;
        height: 5rem;
        padding: 1rem;
        width: 5rem;

    }
}

@media screen and (min-width: 750px) {
    .catalogue-category-body li {
        flex-direction: row;
        width: 100%;
        height: fit-content;
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 1rem;
    }
}

@media screen and (min-width: 1280px) {

}

@media screen and (min-width: 1600px) {

}

@media screen and (min-width: 750px) {

}
