@font-face {
    font-family: 'Avenir';
    src: local('Avenir Medium'), local('Avenir-Medium'),
    url('Avenir-Medium.woff2') format('woff2'),
    url('Avenir-Medium.woff') format('woff'),
    url('Avenir-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

:root {
    --font-color: #3E3936;

    --x-small-font-size: 8px;
    --small-font-size: 12px;
    --medium-font-size: 16px;
    --large-font-size: 20px;
    --x-large-font-size: 24px;
    --xx-large-font-size: 28px;
    --xxx-large-font-size: 36px;

    --xx-small-header-size: 8px;
    --x-small-header-size: 16px;
    --small-header-size: 24px;
    --medium-header-size: 32px;
    --large-header-size: 40px;
    --x-large-header-size: 48px;
    --xx-large-header-size: 56px;
    --xxx-large-header-size: 64px;


    --on-hover-color: rgb(153, 190, 20);
    --original-dark-green: #6d9838;
    --original-light-green: rgb(153, 190, 20);
    --box-shadow-down: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    --navbar-height: 10vh;

    /*
    RESOLUTIONS:
    --phone-breakpoint: 350px;
    --tablet-breakpoint: 750px;
    --small-desktop-breakpoint: 1300px;
    --medium-desktop-breakpoint: 1600px;
    --large-desktop-breakpoint: 1900px;
    */
}

html {
    font-size: 10px;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


input[type=number] {
    -moz-appearance: textfield;
}

body {
    font-family: "Avenir";
    position: relative;
    scrollbar-width: thin;
    scrollbar-color: #555 #f1f1f1;
}

button {
    cursor: pointer;
}

a {
    text-decoration-color: black;
    color: black;
}

footer {
    position: relative;
    height: 25vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    z-index: 10;
}

.footer-head {
    background-color: inherit;
    height: 20vh;
    width: 100%;
    padding: 1rem 0 1rem 8rem;
    display: grid;
    grid-template-rows: repeat(5, 1fr);
    grid-template-columns: repeat(5, 1fr);
    align-items: center;
}

.footer-head > span {
    font-size: 1.7rem;
    color: var(--font-color);
}

.footer-head > div {
    display: flex;
    align-items: center;
}

.footer-head div > span {
    font-size: 1.5rem;
    color: #99be14;

}


.footer-head a {
    text-decoration: none;
    color: #99be14;
}

.footer-up-ubicanos {
    grid-column: 1/2;
    grid-row: 1/3;
    margin-bottom: 1rem;
}

.footer-up-ubicanos-info-div {
    grid-column: 1/4;
    grid-row: 2/4;
}


.footer-up-mail-div {
    grid-column: 1/2;
    grid-row: 3/5;
    margin-left: 2rem;
}

.footer-up-phone-div {
    grid-column: 1/2;
    grid-row: 4/6;
}


.footer-up-especies {
    justify-self: center;
    grid-column: 4/6;
    grid-row: 1/2;
}

.footer-especies-links {
    grid-column: 4/6;
    grid-row: 2/5;
    display: flex;
    flex-direction: column;
    gap: .5rem;
    text-transform: uppercase;
    justify-self: center;
}


.footer-info-icon {
    width: 2rem;
    height: 2rem;
    color: #99be14;
    stroke-width: 1.5px;
    margin-right: .7rem;
}

.aviso-de-privacidad, .codigo-de-etica {
    grid-column: 5/6;
    grid-row: 4/5;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    width: fit-content;
    height: fit-content;
    justify-self: center;
    font-size: 1.5rem;
    text-decoration: underline;
}

.certificacion {
    transition: all .3s;
    border-radius: 3rem;
}

.certificacion:hover {
    transform: scale(110%);
}

.aviso-de-privacidad:hover, .codigo-de-etica:hover {
    color: #99be14;
    transition: all .3s;
}

.codigo-de-etica {
    font-size: 2rem;
    grid-column: 5/6;
    grid-row: 5/6;
}

.footer-contact {
    width: 70%;
    height: 100%;
}

.footer-body {
    background-color: #99be14;
    width: 100%;
    height: 5vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 4rem;
    padding-left: 4rem;
    font-size: 2rem;
}

.footer-body > ul:first-child {
    display: flex;
    gap: 2rem;
}

.social-media-logos {
    display: flex;
    align-items: start;
    justify-content: start;
    list-style: none;
}


.social-media-logos li a > * {
    height: 3rem;
    width: 3rem;
    margin-top: 1rem;
    fill: white;
    stroke-width: 1px;
}

.footer-body-rights {
    display: flex;
    font-size: 1.5rem;
    flex-direction: column;
    text-align: end;
    align-content: center;
    justify-content: center;
}

.footer-body-rights > small:first-of-type {
    color: white;
}

/*404 style*/

.no-match-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.no-match-container img {

    height: 40vh;
    width: 26.5vw;
    border-radius: 2rem;
}

.link-de-regreso {
    margin-top: 4rem;
    font-size: 4rem;
    white-space: break-spaces;
    width: 60vw;
    text-align: center;
    color: white;

}

.active-text {
    color: #99be14;
}

.podcast {
    position: fixed;
    /*top: calc(var(--navbar-height) + 1rem);*/
    bottom: 0;
    right: 0%;
    width: 45%;
    height: fit-content;
    border-radius: 12px;
    border: none;
    opacity: 75%;
    z-index: 9;
    transform: translateY(40%);
}

.not-display {
    display: none;
}

.inline-block {
    display: inline-block;
}


@media (min-width: 350px) and (max-width: 750px) {
    html {
        font-size: 5px;
    }

    .podcast {
        width: 100%;
    }
}

@media (min-width: 1300px) {
    html {
        font-size: 6px;
    }
}


@media (min-width: 1600px) {
    html {
        font-size: 8px;
    }
}

@media (min-width: 1900px) {
    html {
        font-size: 10px;
    }
}

